import withTransparentBackground from 'ui/hoc/withTransparentBackground';
import Login from 'features/Login';
import Signup from 'features/Signup/Modal';
import PinSetting from 'features/UserArea/PinSetting';
import Deposit from 'features/UserArea/Deposit';
import DepositAmountForm from 'features/UserArea/Deposit/AmountForm';
import DepositLimits from 'features/UserArea/DepositLimits';
import Transactions from 'features/UserArea/Transactions';
import SelfExclusion from 'features/UserArea/SelfExclusion';
import ForgotPassword from 'features/Login/ForgotPassword';
import Withdraw from 'features/UserArea/Withdraw';
import StatusOfAction from 'features/SystemHandler/StatusOfAction';
import UserProfile from 'features/UserArea/UserProfile';
import DatePicker from 'ui/DatePicker';
import { CalendarDatePicker as EventBoardDatePicker } from 'features/EventBoard/components/DateSelection.tsx';
import { CalendarDatePicker as ResultsDatePicker } from 'features/ResultsNew/components/DateSelection.tsx';
import ProductsShortcuts from 'features/Spilklub/components/ProductShortcuts';
import QuickPickAmount from 'features/QuickPick/QuickPickAmount';
import QuickPickReceiptModal from 'features/QuickPick/QuickPickReceipt/QuickPickReceiptModal';
import OSShortcutInfo from 'features/SystemHandler/OSShortcutInfo';
import QRCodeModal from 'features/Articles/QRCodeModal';
import Voucher from 'features/UserArea/Voucher';
import GameStatistic from 'features/Game/GameStatistic';
import Replay from 'features/RaceCard/RaceCardReplays/Replay';
import { MODALS } from 'features/Spilklub/config';
import BetBuddy from 'features/Spilklub/Mobile';
import SpilklubDesktop from 'features/Spilklub/Desktop';
import getTexts from 'utils/localization';
import SuccessBetSaving from 'features/MobileBet/SuccessBetSaving';
import MobileBetList from 'features/MobileBet/MobileBetList';
import MobileBetContainer from 'features/MobileBet/MobileBetContainer';
import ShopList from 'features/MobileBet/ShopList';
import EditSubscriptionsPopup from 'features/Spilklub/Mobile/EditSubscriptionsPopup.tsx';
import LeaveGroupPopup from 'features/Spilklub/components/LeaveGroupPopup';
import TempUser from 'features/Signup/TempUser';
import SessionExpired from 'features/Login/SessionExpired';
import LimitExceed from 'features/UserArea/Deposit/LimitExceed';
import NewsletterModal from 'features/UserArea/Newsletter/NewsletterModal';
import LoginPrompt from 'features/Login/LoginPrompt';
import BetSlipMobile from 'features/BetSlip/platforms/Mobile';
import BetSlipMobile2 from 'features/BetSlip2/platforms/Mobile';
import UserAlreadyExistPopup from 'features/Signup/UserExists';
import LiveStreamTooltipOverlayed from 'features/StreamVideo/LiveStreamTooltipOverlayed';
import LeadPopup from 'features/UserArea/PinSetting/LeadPopup';
import CPRConfirmModal from 'features/Login/CPRConfirmModal';
import PlayerModal from 'ui/YouTubeVideo/PlayerModal.tsx';
import Receipt from 'features/BetSlip/components/Receipt/ReceiptMobile';
import Receipt2 from 'features/BetSlip2/components/Receipt/ReceiptMobile';
import SmartLynReceiptModal from 'features/QuickPick/QuickPickReceipt/SmartLynReceiptModal';
import SpilIdModal from 'features/SpilID/SpilIdModal';
import PhysicalCardPreview from 'features/SpilID/PhysicalCardPreview';
import PhysicalCardForm from 'features/SpilID/PhysicalCardForm';
import RemoveBonusConfirmModal from 'features/UserArea/Withdraw/RemoveBonusConfirmModal';
import SelfExcludedModal from 'features/UserArea/SelfExclusion/SelfExcludedModal';
import SpilIDPinCode from 'features/SpilID/SpilIDPinCode';
import DesktopLogin from 'features/Login/DesktopLogin';
import {
    ProductDescriptionPopup,
    RemoveProductTipsPopup,
} from 'features/TrackPage/components/ProductTips/ProductTips';
import UserAreaDesktop from 'features/UserArea/UserAreaMenu/UserAreaMenuDesktop';
import UserAreaMobile from 'features/UserArea/UserAreaMenu/UserAreaMenuMobile';
import { NEW_TRACK_PAGE } from './main';
import RestorePassword from 'features/Login/RestorePassword';
import PinPopup from 'features/Login/PinPopup';
import InstallPWAModal from 'features/InstallPWAModal';
import PayoutConfirmationPopup from 'features/History/RetailBets/PayoutConfirmationPopup';
import PayoutSuccessPopup from 'features/History/RetailBets/PayoutSuccessPopup';
import PayoutFailedPopup from 'features/History/RetailBets/PayoutFailedPopup';

const t = getTexts();

const ModalsMapConfig = {
    LOGIN: Login,
    SIGNUP: Signup,
    PIN_SETTING: PinSetting,
    PIN_POPUP: withTransparentBackground(PinPopup),
    DEPOSIT: Deposit,
    DEPOSIT_MODAL: DepositAmountForm,
    DEPOSIT_LIMITS: DepositLimits,
    TRANSACTIONS: Transactions,
    WITHDRAW: Withdraw,
    SELF_EXCLUSION: SelfExclusion,
    VOUCHER: Voucher,
    NEWSLETTER: NewsletterModal,
    FORGOT_PASSWORD: ForgotPassword,
    STATUS_OF_ACTION: StatusOfAction,
    PROFILE: UserProfile,
    DATE_PICKER: DatePicker,
    EVENT_BOARD_DATE_PICKER: EventBoardDatePicker,
    RESULTS_DATE_PICKER: ResultsDatePicker,
    PRODUCTS_SHORTCUTS: ProductsShortcuts,
    QUICK_PICK_AMOUNT: QuickPickAmount,
    QUICK_PICK_RECEIPT: QuickPickReceiptModal,
    OS_SHORTCUT: OSShortcutInfo,
    PIN_SETUP_LEAD: LeadPopup,
    QR_CODE_MODAL: QRCodeModal,
    GAME_STATISTIC: GameStatistic,
    REPLAY: Replay,
    // [MODALS.BET_BUDDY_BETSLIP]: BetBuddyBetSlip,
    SAVED_BET_CONTAINER: MobileBetContainer,
    SAVED_BET_LIST: MobileBetList,
    SUCCESS_BET_SAVING: SuccessBetSaving,
    SHOP_LIST: ShopList,
    EDIT_SUBSCRIPTIONS: EditSubscriptionsPopup,
    [MODALS.BET_BUDDY_ENTRY]: BetBuddy,
    LEAVE_GROUP: LeaveGroupPopup,
    TEMP_USER: withTransparentBackground(TempUser),
    SESSION_EXPIRED: withTransparentBackground(SessionExpired),
    DEPOSIT_LIMIT_EXCEED: withTransparentBackground(LimitExceed),
    LOGIN_PROMPT: withTransparentBackground(LoginPrompt),
    USER_EXISTS: withTransparentBackground(UserAlreadyExistPopup),
    LIVE_STREAM_TOOLTIP_OVERLAY: LiveStreamTooltipOverlayed,
    CPR_CONFIRMATION: CPRConfirmModal,
    YOUTUBE_PLAYER_POPUP: PlayerModal,
    RECEIPT: NEW_TRACK_PAGE ? Receipt2 : Receipt,
    SMART_LYN_RECEIPT: SmartLynReceiptModal,
    SPIL_ID: SpilIdModal,
    SPIL_ID_PC_PREVIEW: PhysicalCardPreview,
    SPIL_ID_PC_FORM: PhysicalCardForm,
    SPIL_ID_PIN_CODE: SpilIDPinCode,
    REMOVE_BONUS_CONFIRM: withTransparentBackground(RemoveBonusConfirmModal),
    SELF_EXCLUDED_MODAL: SelfExcludedModal,
    INSTALL_PWA_MODAL: withTransparentBackground(InstallPWAModal),
};

export const ROUTER_POPUPS = {
    LOGIN: {
        id: 'LOGIN',
        pathname: '/log-ind',
        Component: DesktopLogin,
        defaultProps: {},
        platforms: ['desktop'],
    },
    RESTORE_PASS: {
        id: 'RESTORE_PASS',
        pathname: '/mitid-verify/forgotpassword',
        Component: RestorePassword,
    },
    PRODUCT_DESCRIPTION: {
        id: 'PRODUCT_DESCRIPTION',
        pathname: '/product/:productId',
        Component: ProductDescriptionPopup,
    },
    PRODUCT_DESCRIPTION_REMOVE: {
        id: 'PRODUCT_DESCRIPTION_REMOVE',
        pathname: '/skjul-denne-guide',
        Component: RemoveProductTipsPopup,
    },
    SPILKLUB_DESKTOP: {
        id: 'SPILKLUB_DESKTOP',
        pathname: '/spil-klub25',
        Component: SpilklubDesktop,
        platforms: ['desktop'],
    },
    USER_AREA_DESKTOP: {
        id: 'USER_AREA_DESKTOP',
        pathname: '/konto/:subpage?',
        Component: UserAreaDesktop,
        platforms: ['desktop'],
    },
    USER_AREA_MOBILE: {
        id: 'USER_AREA_MOBILE',
        pathname: '/konto',
        Component: UserAreaMobile,
        platforms: ['mobile'],
    },
    BETSLIP_MOBILE: {
        id: 'BETSLIP_MOBILE',
        pathname: '/betslip',
        Component: NEW_TRACK_PAGE ? BetSlipMobile2 : BetSlipMobile,
        platforms: ['mobile'],
    },
    RETAIL_PAYOUT_CONFIRMATION: {
        id: 'RETAIL_PAYOUT_CONFIRMATION',
        pathname: '/bekræfte-udbetaling',
        Component: PayoutConfirmationPopup,
    },
    RETAIL_PAYOUT_SUCCESS: {
        id: 'RETAIL_PAYOUT_SUCCESS',
        pathname: '/vellykket-udbetaling',
        Component: PayoutSuccessPopup,
        platforms: ['mobile'],
    },
    RETAIL_PAYOUT_FAILED: {
        id: 'RETAIL_PAYOUT_FAILED',
        pathname: '/mislykket-udbetaling',
        Component: PayoutFailedPopup,
        platforms: ['mobile'],
    },
};

const ModalLocationSettings = {
    LOGIN: {
        pathname: '/log-ind',
        goBackOnClose: true,
        goBackBlacklist: [{ pathname: '/promo/velkommen' }],
    },
};

const ModalsByURLSearchParams = {
    spilid: {
        modalId: 'SPIL_ID',
        priority: 2,
    },
    'deposit-success': {
        modalId: 'STATUS_OF_ACTION',
        data: {
            success: true,
            title: t.userArea.deposit.success,
        },
    },
    'deposit-error': {
        modalId: 'STATUS_OF_ACTION',
        data: {
            success: false,
            title: t.userArea.deposit.error,
            text: t.userArea.deposit.fundErrors[
                new URL(window.location.href).searchParams.get('reason')
            ],
        },
    },
    'deposit-form': {
        modalId: 'DEPOSIT_MODAL',
        data: {
            success: true,
            title: t.userArea.deposit.success,
            disableCloseButton: true,
        },
    },
};

export { ModalsMapConfig, ModalLocationSettings, ModalsByURLSearchParams };
