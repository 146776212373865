import SEIcon from 'images/flags/SE.png';
import DKIcon from 'images/flags/DK.png';
import AUIcon from 'images/flags/AU.png';
import FRIcon from 'images/flags/FR.png';
import UKIcon from 'images/flags/UK.png';
import ZAIcon from 'images/flags/ZA.png';

export const STREAM_MODE_ATG = 'STREAM_MODE_ATG';
export const STREAM_MODE_FT = 'STREAM_MODE_FT';
export const STREAM_MODE_FTP = 'STREAM_MODE_FTP';
export const STREAM_MODE_FR = 'STREAM_MODE_FR';
export const STREAM_MODE_SA = 'STREAM_MODE_SA';
export const STREAM_MODE_AU = 'STREAM_MODE_AU';
export const STREAM_MODE_UK = 'STREAM_MODE_UK';

export const src = {
    [STREAM_MODE_FT]: 'https://kanal75-lab-b.akamaized.net/live/Data/Kanal-1/HLS_B/Kanal-1.m3u8',
    [STREAM_MODE_FTP]: 'https://kanal75-lab.akamaized.net/live/Data/Kanal-3/HLS/Kanal-3.m3u8',
    [STREAM_MODE_ATG]: 'https://kanal75-lab-b.akamaized.net/live/Data/Kanal-2/HLS_B/Kanal-2.m3u8',
    [STREAM_MODE_FR]:
        'https://httpcache0-00688-cacheliveedge0.dna.ip-only.net/00688-cacheliveedge0/out/u/atg_sdi_14_premium.m3u8',
    [STREAM_MODE_SA]:
        'https://httpcache0-00688-cacheliveedge0.dna.ip-only.net/00688-cacheliveedge3/out/u/atg_sdi_11_derby25_za.m3u8',
    [STREAM_MODE_AU]:
        'https://httpcache0-00688-cacheliveedge0.dna.ip-only.net/00688-cacheliveedge0/out/u/atg_sdi_3_premium.m3u8',
    [STREAM_MODE_UK]:
        'https://httpcache0-00688-cacheliveedge0.dna.ip-only.net/00688-cacheliveedge3/out/u/atg_sdi_12_derby25_gb.m3u8',
};

export const icons = {
    [STREAM_MODE_ATG]: SEIcon,
    [STREAM_MODE_FR]: FRIcon,
    [STREAM_MODE_SA]: ZAIcon,
    [STREAM_MODE_AU]: AUIcon,
    [STREAM_MODE_UK]: UKIcon,
    [STREAM_MODE_FT]: DKIcon,
    [STREAM_MODE_FTP]: DKIcon,
};

export const streamCountry = {
    DK: STREAM_MODE_FT,
    SE: STREAM_MODE_ATG,
    FR: STREAM_MODE_FR,
    ZA: STREAM_MODE_SA,
    AU: STREAM_MODE_AU,
    GB: STREAM_MODE_UK,
};
