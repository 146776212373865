import React, { MouseEvent, useEffect, useCallback, useRef, useState, memo } from 'react';
import styled from 'styled-components';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { Overlay } from './Popup';
import Modal from './ModalContent';
import {
    extractCurrentTouchPositionY,
    getVerticalHeight,
    verticallyMoveTo,
    getSwipingDirectionVertical,
} from 'utils/DOM';
import Icon from './Icon';

export const animationDurationMs: number = 300;

const animationFunction = 'cubic-bezier(0.4, 0, 0.2, 1)';

interface ContainerProps {
    padding?: string;
    height?: string;
    transition?: boolean;
    maxHeight?: number;
    containerStyles?: React.CSSProperties;
    radius?: string;
    background?: string;
}

const Container = styled.div<ContainerProps>`
    position: absolute;
    top: 100%;
    overflow-y: scroll;
    width: 100%;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    height: ${props => props.height};
    ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}px`};
    border-top-left-radius: ${props => props.radius || '5px'};
    border-top-right-radius: ${props => props.radius || '5px'};
    background: ${props => props.background || '#f4f7fc'};
    padding: ${props => props.padding ?? 0};
    transition: ${props =>
        props.transition ? `all ${animationDurationMs}ms ${animationFunction}` : 'initial'};
`;

const DragIndicatorWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 35px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
`;

const DragIndicator = styled.div`
    width: 40px;
    height: 4px;
    border-radius: 6px;
    background: #9098a9;
    margin: 10px auto 0;
`;

const CloseButtonArea = styled.div`
    position: absolute;
    right: 35px;
    top: 33px;
    border-radius: 5px;
    background: #333333;
`;

const CloseIcon = styled(Icon).attrs({ color: 'white' })`
    width: 10px;
    height: 10px;
    padding: 8px;
`;

export const DraggablePopupTitle = styled.h3`
    font-weight: 900;
    font-size: 21px;
`;

interface RenderPropsToPass {
    close(): void;
}

interface RenderProps {
    (props: RenderPropsToPass): React.ReactElement;
}

interface DraggablePopupProps {
    children: React.ReactElement | RenderProps;
    closeHandler?: () => void;
    closeBtn?: boolean | undefined;
    withoutDragIndicator?: boolean;
}

function moveElementByRef(ref: { current: HTMLDivElement }, e: TouchEvent) {
    const positionY = extractCurrentTouchPositionY(e);
    verticallyMoveTo(ref, positionY);
}

const vh = getVerticalHeight();

const bodyScrollLockSettings = {
    allowTouchMove: (el: Element) => {
        console.log(el);
        return el.getAttribute('data-body-scroll-lock-ignore') !== null;
        // while (el && el !== document.body) {
        //     if (el.getAttribute('body-scroll-lock-ignore') !== null) {
        //         return true;
        //     }
        //
        //     if (el.parentElement) {
        //         el = el.parentElement;
        //     }
        // }
    },
};

const DraggablePopup = ({
    children,
    padding,
    closeHandler,
    height = '90%',
    radius,
    background,
    closeBtn = false,
    withoutDragIndicator = false,
    containerStyles = {},
}: DraggablePopupProps & ContainerProps) => {
    const [transitionEnabled, setTransitionEnabled] = useState(true);

    const ref = useRef(document.createElement('div') as HTMLDivElement);
    const dragRef = useRef(document.createElement('div') as HTMLDivElement);

    const currentYPosition = useRef(0);

    const maxHeight = window.innerHeight - 90;

    const expand = useCallback(() => {
        const offsetTop =
            height === 'auto'
                ? ref.current.offsetHeight > maxHeight
                    ? maxHeight + 'px'
                    : ref.current.offsetHeight + 'px'
                : height;

        verticallyMoveTo(ref, `calc(100% - ${offsetTop})`);
    }, [height, maxHeight]);

    const collapse = () => {
        verticallyMoveTo(ref, vh);
    };

    const close = useCallback(() => {
        collapse();
        enableBodyScroll(ref.current);

        setTimeout(() => {
            closeHandler && closeHandler();
        }, animationDurationMs);
    }, [closeHandler]);

    useEffect(() => {
        disableBodyScroll(ref.current, {
            allowTouchMove: (el: Element) => {
                return (
                    el &&
                    el !== document.body &&
                    el.getAttribute('data-body-scroll-lock-ignore') !== null
                );
                // while (el && el !== document.body) {
                //     if (el.getAttribute('body-scroll-lock-ignore') !== null) {
                //         return true;
                //     }
                //
                //     if (el.parentElement) {
                //         el = el.parentElement;
                //     }
                // }
            },
        });
        expand();

        let swipingDirection = '';
        const movePopup = (e: TouchEvent) => {
            moveElementByRef(ref, e);
            swipingDirection = getSwipingDirectionVertical(e, currentYPosition.current);
            currentYPosition.current = extractCurrentTouchPositionY(e);
        };
        const startSwiping = () => {
            // disable transition for smooth popup moving
            setTransitionEnabled(false);
        };
        const finishSwiping = () => {
            // enable transition for animated hiding
            setTransitionEnabled(true);

            if (swipingDirection === 'down') {
                close();
            } else {
                expand();
            }
        };

        dragRef.current.addEventListener('touchstart', startSwiping);
        dragRef.current.addEventListener('touchmove', movePopup);
        dragRef.current.addEventListener('touchend', finishSwiping);

        return () => clearAllBodyScrollLocks();
    }, [close, expand]);

    return (
        <Modal>
            <Overlay onClick={close}>
                <Container
                    ref={ref}
                    padding={padding}
                    transition={transitionEnabled}
                    height={height}
                    radius={radius}
                    background={background}
                    maxHeight={maxHeight}
                    onClick={(e: MouseEvent<HTMLDivElement>) => {
                        e.stopPropagation();
                    }}
                    data-body-scroll-lock-ignore
                    style={containerStyles}
                >
                    {!withoutDragIndicator && (
                        <DragIndicatorWrapper ref={dragRef}>
                            <DragIndicator />
                        </DragIndicatorWrapper>
                    )}
                    {closeBtn && (
                        <CloseButtonArea onClick={close}>
                            <CloseIcon iclose />
                        </CloseButtonArea>
                    )}
                    {typeof children === 'function' ? children({ close }) : children}
                </Container>
            </Overlay>
        </Modal>
    );
};

export default memo(DraggablePopup);
