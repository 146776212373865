import React, { useCallback } from 'react';
import Wrapper from 'ui/Wrapper';
import { byPlatform, isDesktop } from 'utils/platforms';
import FlexWrapper from 'ui/FlexWrapper';
import Text from 'ui/Text';
import Icon from 'ui/Icon';
import Divider from 'ui/Divider';
import getTexts from 'utils/localization';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showUnwrappedModal } from 'common/actions/uiActions';
import { MODALS, SCREENS } from '../config';
import FadeIn from 'ui/animations/FadeIn';
import Card, { CommonCardWrapper, CommonTextWithBg } from './Card';
import { getUrl, showPopup } from 'utils/navigation';
import styled from 'styled-components';
import useClubsQuery from '../hooks/useClubsQuery';
import useGroups from '../hooks/useGroups';

const CardWrapper = styled(CommonCardWrapper)`
    padding: 20px;
    cursor: pointer;
    border: none;
`;

const CardsWrapper = styled(Wrapper)`
    display: grid;
    gap: 10px;
    padding: ${({ raceCalendarLayout }) => (raceCalendarLayout ? '0 20px' : '0')};
    grid-template-columns: 50% 50%;
    @media (max-width: 990px) {
        grid-template-columns: 100%;
    }
    div {
        border-radius: 5px;
    }
`;

const TextWithBg = styled(CommonTextWithBg)`
    height: auto;
    line-height: normal;
    display: inline-block;
    margin: 0;
    padding: 5px 10px;
    border-radius: 3px !important;
`;

const SpilklubGroupsSection = ({ showRaceCalendarLayout = false }) => {
    const t = getTexts();
    const history = useHistory();
    const dispatch = useDispatch();
    const { enableGroupMode } = useGroups();

    const { data, isSuccess } = useClubsQuery({ groupsByDate: showRaceCalendarLayout });

    const openSpilklub = useCallback(() => {
        if (isDesktop) {
            showPopup('SPILKLUB_DESKTOP', { showScreens: [SCREENS.GROUPS] });
        } else {
            dispatch(
                showUnwrappedModal(MODALS.BET_BUDDY_ENTRY, {
                    showScreens: [SCREENS.GROUPS],
                    locationToGoBack: history.location.pathname,
                })
            );
            history.push('/spil-klub25');
        }
    }, [dispatch, history]);

    const openGroup = groupId => {
        openSpilklub();
        enableGroupMode(groupId, false, true);
    };

    if (!isSuccess || !data.length) {
        return null;
    }

    return (
        <>
            <Wrapper padding={byPlatform('0 15px', '0')}>
                <Wrapper padding={byPlatform('10px 20px', '10px 30px')}>
                    <FlexWrapper
                        align={'space-between'}
                        padding={'0'}
                        direction={'row'}
                        alignItems={'center'}
                    >
                        <Text bold size={byPlatform('20', '32')} padding={'0'} height={'100%'}>
                            {t.SpilklubSection.title}
                        </Text>
                        <FlexWrapper
                            alignItems={'center'}
                            padding={'0'}
                            direction={'row'}
                            width={'auto'}
                            style={{ cursor: 'pointer' }}
                            onClick={openSpilklub}
                        >
                            <Text color={'#2CB16F'} size={byPlatform('x07', 'x1.5')} bold>
                                {t.SpilklubSection.buttonText}
                            </Text>
                            <Icon
                                iarrowsmooth
                                rotation={90}
                                color={'green'}
                                size={'x025'}
                                margin={byPlatform('0 0 0 5px', '0 0 0 9px')}
                            />
                        </FlexWrapper>
                    </FlexWrapper>
                    <Text size={byPlatform('x07', '20')}>{t.SpilklubSection.description}</Text>
                </Wrapper>
                <CardsWrapper raceCalendarLayout={showRaceCalendarLayout}>
                    {byPlatform(data.slice(0, 2), data).map((group, idx) => (
                        <div
                            style={{ padding: 0 }}
                            key={`${group.poolTemplateId}-${idx}-${showRaceCalendarLayout}`}
                        >
                            <FadeIn>
                                <Card
                                    title={group.name}
                                    showPoolSummary={showRaceCalendarLayout}
                                    boughtShares={group.boughtSharesNumber}
                                    showBottomLineContent={false}
                                    showBoughtSharesLabel={!showRaceCalendarLayout}
                                    settings={group.settings}
                                    activePools={group.activePools}
                                    image={getUrl(group.avatarUrl)}
                                    ownerId={group.ownerId}
                                    groupId={group.poolTemplateId}
                                    membersCount={group.membersCount}
                                    styled={{ CardWrapper, TextWithBg }}
                                    favoriteGroups={[]}
                                    noFavouriteButton
                                    showMembersCount={false}
                                    // setFavorite={() =>
                                    //   updateFavoriteGroup(group.id)
                                    // }
                                    // closable={personalGroup}
                                    onClick={() => openGroup(group.poolTemplateId)}
                                    disabled={group.groupClosed}
                                />
                            </FadeIn>
                        </div>
                    ))}
                </CardsWrapper>
            </Wrapper>
            <Divider margin={'35px 0 15px'} color={'#DFE3ED'} />
        </>
    );
};

export default SpilklubGroupsSection;
