import * as theme from '../themes';

const colors = theme.COLORS;
const bg = theme.BACKGROUNDS;

export function getBackgroundByProps(props) {
    let btnBg = bg.primary;

    if (props.accent) {
        btnBg = bg.accent;
    } else if (props.secondary) {
        btnBg = bg.secondary;
    } else if (props.blue) {
        btnBg = bg.blue;
    } else if (props.grey) {
        btnBg = bg.grey;
    } else if (props.white) {
        btnBg = bg.white;
    } else if (props.red) {
        btnBg = bg.red;
    } else if (props.transperent || props.link || props.black) {
        btnBg = bg.none;
    } else if (props.lightGrey) {
        btnBg = bg.lightGrey;
    } else if (props.accentAlt) {
        btnBg = bg.accentAlt;
    } else if (props.specialRed) {
        btnBg = colors.special.red;
    } else if (props.green) {
        btnBg = colors.primary;
    }

    return btnBg;
}

export function getColorByProps(props) {
    let clr = colors.primary;

    if (props.accent) {
        clr = colors.accent;
    } else if (props.secondary) {
        clr = colors.secondary;
    } else if (props.blue) {
        clr = colors.blue;
    } else if (props.grey) {
        clr = colors.grey;
    } else if (props.red) {
        clr = colors.red;
    } else if (props.black) {
        clr = colors.black;
    } else if (props.transperent || props.link || props.white) {
        clr = colors.white;
    }

    return clr;
}

export function getTextColorByProps(props) {
    let clr = colors.primaryText;

    if (props.accent) {
        clr = colors.accentText;
    } else if (props.primary) {
        clr = colors.primary;
    } else if (props.secondary) {
        clr = colors.secondaryText;
    } else if (props.blue) {
        clr = colors.blue;
    } else if (props.grey) {
        clr = colors.grey;
    } else if (props.red) {
        clr = colors.red;
    } else if (props.black) {
        clr = colors.black;
    } else if (props.lightgreen) {
        clr = colors.lightGreen;
    } else if (props.white) {
        clr = colors.white;
    } else if (props.calm) {
        clr = colors.special.calm;
    } else if (props.green) {
        clr = colors.special.green;
    } else if (props.muted) {
        clr = colors.mutedText;
    }

    return clr;
}

export const DATEPICKER_THEME_CONFIG = {
    BselectionColor: colors.accent,
    textColor: {
        default: '#333',
        active: '#333',
    },
    weekdayColor: colors.lightGrey,
    headerColor: colors.primary,
    floatingNav: {
        background: colors.primary,
        color: '#FFF',
        chevron: '#FFA726',
        opacity: '0.8',
    },
};

export const numberToPixel = (number, defaultNumber) => {
    return number ? number + 'px' : defaultNumber + 'px';
};
